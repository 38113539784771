<template>
  <button-add
    v-if="id"
    label="Copy ID"
    tooltip-direction="bottom"
    icon="vpn_key"
    text
    display="block"
    color="focus"
    @click="copyIdToClipboard(id)"
  ></button-add>
</template>

<script>
  import util from '../../utlity/util'
  import { mapActions } from 'vuex'
  import ButtonAdd from './ButtonAdd'

  export default {
    name: 'IdField',
    components: {ButtonAdd},
    props: ['id'],
    methods: {
      ...mapActions(['showSnackbar']),
      copyIdToClipboard(id) {
        const success = util.copyTextToClipboard(id)
        this.showSnackbar({
          text: success ? `ID has been copied to clipboard` : 'There was an error while copying to the clipboard.',
          type: success ? 'success' : 'error',
        })
      },
    },
  }
</script>
