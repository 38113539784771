<template>
  <v-btn
    v-show="true"
    :text="text"
    :color="dark ? 'white' : color"
    :class="text ? '' : 'elevation-4'"
    :outlined="outlined"
    icon
    @click="click"
  >
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'ButtonAdd',
    props: {
      label: {
        type: String,
        default: 'Add',
      },
      text: {
        type: Boolean,
        default: true,
      },
      display: {
        type: String,
        default: 'inline',
      },
      dark: {
        type: Boolean,
        default: false,
        required: false,
      },
      color: {
        type: String,
        default: 'accent',
        required: false,
      },
      outlined: {
        type: Boolean,
        default: false,
        required: false,
      },
      icon: {
        type: String,
        default: 'add',
        required: false,
      },
    },
    methods: {
      click(e) {
        this.$emit('click', e)
      },
    },
  }
</script>
